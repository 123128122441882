@media screen and (max-width: 1369px) {
    html {
        font-size: 14px;
    }

    h2{
        text-transform: none;
    }

    .sidebar .credit-card-info-section{
        h2{
            text-align: left;
            margin-left: 1rem;
        }
    }

    .circle {       
        top: -300px;
        left: -300px;
        
        svg{
            width: 90%;        
        }
        
        &.bottom{            
            bottom: -300px;
            right: -300px;
    
        }
    }

    .payment-page{
        div.box-container:nth-of-type(3) {
            margin-top: 2rem;
        }
    }
}