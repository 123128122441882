.fade-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-right-enter.fade-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in;
}

.fade-right-exit {
  opacity: 1;
  transform: 0;
}

.fade-right-exit.fade-right-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-in;
}

.fade-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-left-enter.fade-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in;
}

.fade-left-exit {
  opacity: 1;
  transform: 0;
}

.fade-left-exit.fade-left-exit-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition: all 300ms ease-in;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

@keyframes pulse {
    0% {
      transform: scale(1, 1);
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
}
