html {
    font-family: $font-family;
    font-size: 18px;
}

/** LOGO **/
.logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: $color-second;
    position: absolute;
    left: 1rem;
    top: 2rem;
        left: 2rem;
    &.white{
        color: $white;
    }
    &.bottom{
        bottom: 1rem;
    }    
    &.top{
        
    }
    &.inside-circle{
        top: 20%;
        left: 7%;
        font-size: 4rem;
    }
}

/** BACKGROUND **/
.bg-light {
    background-color: $color-main;
    z-index: 100;
}

.bg-dark {
    background-color: $color-second;
    color: $white;
    z-index: 100;
}


/** HEADINGS **/
h2 {
    color:$color-second;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 2rem;
    font-weight: bold;
}


/** CIRCLE **/
.circle {
    // border: 5rem solid $color-third;
	// border-radius: 50%;
	// opacity: 1;
	// width: 75%;
    // height: 115%;
    position: absolute;
    top: -200px;
    left: -200px;
    z-index: -1;
    
    &.bottom{
        top: auto;
        left: auto;
        bottom: -200px;
        right: -200px;

    }
}





/* BOX */
.box {
    background-color: $white;
    border-radius:10px;
    padding: 1rem 0;
    // min-height: 100px;  
    box-shadow: 3px 5px 6px 0px $shadow-color;
}

/** SCROLLBAR **/
::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
    background-color: $color-main!important;
}

::-webkit-scrollbar {
    width: 7px;
    background-color: $color-second!important;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color-second!important;
}