button {
    background-color: $color-third; 
    border-radius: 5px;
    color: $white;
    border: 1px solid $color-third;
    text-transform: uppercase;
    font-size: 1rem;
    cursor: pointer;
    display: inline-flex;    
    align-items: center;
    justify-content: space-around;
    padding: .7rem 0 .7rem 3.8rem;
    min-width: 200px;

    &:focus {
        outline: none;
    }   

    &:hover{
        // box-shadow: 3px 5px 6px 0px $black;
        // transform: scale(1.025, 1.05);
    }
    
    &.disabled{
        pointer-events: none;
        background-color: $color-main;
        border-color: $color-main;
        cursor: not-allowed;
    }

    &.naked-button{
        background-color: transparent;
        border: none;
        text-decoration: underline;
        padding: 0;
        &.dark-button{
            color: $black;
        }

        &:hover{
            box-shadow: none;
            transform: none;
            // font-size: 1.1rem;
            // color: $red;
            // text-decoration: none
        }
    }

    &.small-button{
        min-width: 100px;
    }

    &.big-button{
        padding: .7rem 0 .7rem 6rem;
        min-width: 13rem;       
    }

    img{
        width: 2rem;
    }
}

.bg-button-round, .button-round {    
    height: 10rem;
    width: 10rem;
    display: flex;
    justify-content: center;   
    flex-direction: column;
    text-align: center;
    border-radius: 50%;
    
}

.bg-button-round {
    animation: pulse 1.2s ease infinite;
    background: $color-second;
    position: absolute;
  
}

.button-round {
    background-color: $color-second;   
    color: $white;   
    box-shadow: 3px 5px 6px 0px $shadow-color;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    line-height: 2.5rem;
    text-transform: uppercase;
    // &:hover{       
    //     transform: scale(1.025, 1.05);
    // }
}