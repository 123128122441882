
.popup-container {
    position: fixed;              
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 701;
    box-sizing: border-box;
    color: $color-second;
    background-color: $white !important;
    border: 1px solid $color-main;
    border-radius: 10px;  
    padding: 1.5rem;
    min-height: 30%;
    height: 50%;
    overflow: hidden;
    min-width: 40%;

    &.popup-small {
        height: 35%;
        .popup-content {
            width: 85%;
            margin: 0 auto;
            line-height: 1.5rem;
        }
    }

    &.popup-medium {
        height: 60%;
        .popup-content {
            height: 75%;
        }
    }
    
    &.popup-large {
        height: 70%;
        min-width: 50%;
        .popup-content {
            height: 75%;   
            width: 85%;
            margin: 0 auto; 
        }
    }

    &.overflow-visible{
        overflow: visible;       
        .popup-content {
            overflow: visible;
            overflow-y: visible;
        }
    }

    .popup-header {
        text-transform: uppercase;
    }

    .popup-content{        
        min-height: 40%;   
        overflow: hidden;
        overflow-y: auto;
        p {
            margin: 0.5rem 0;
        }
        &.flex {
             display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .react-select__menu-list{
            max-height: 160px;
        }
        &.no-content {
            display: none;
        }
       
    }

    h3 {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }

    li {
        margin: 0.5rem 0;
    }

    .error-message {
        font-family: $font-family;
        font-size: 1.5rem;
    }
}

.popup-background {  
    position: fixed;
    width: 100%;    
    height: 100%;
    background: $color-second;
    opacity: .4;
    z-index: 700;
    top: 0;
    left: 0;
}

.footer-popup {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;  
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 800;
    cursor: pointer;
    transform: rotate(45deg);
}

