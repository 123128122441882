.fullscreen {
    height: 100vh;
    .bg-dark, .bg-light{
        height: inherit;
    }
}

$widthInput: 80%;
.login-page {
    .bg-dark {
        display: flex;
        align-items: center;
    }
    .login-container {
        margin-left: -275px;
        height: 400px;
        width: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .input-container {
            display: flex;
            flex-direction: column;
            padding: 2rem 0;
            height: 60%;
            justify-content: center;
            
            .icon{
                color: $black;
            }
            
            input {
                width: $widthInput;
            }
        }
        .btn-container{
            width: $widthInput; 
            display: flex;
            justify-content: flex-end;
        }
    }
}