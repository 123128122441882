

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sad:before {
  content: "\e900";
}
.icon-address:before {
  content: "\e901";
}
.icon-arroba:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-clapping:before {
  content: "\e904";
}
.icon-coin:before {
  content: "\e905";
}
.icon-credit-card:before {
  content: "\e906";
}
.icon-credit-cards-payment:before {
  content: "\e907";
}
.icon-earth:before {
  content: "\e908";
}
.icon-euro-coin:before {
  content: "\e909";
}
.icon-euro:before {
  content: "\e90a";
}
.icon-invoice:before {
  content: "\e90b";
}
.icon-key:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90d";
}
.icon-phone-call:before {
  content: "\e90e";
}
.icon-placeholder-filled-point:before {
  content: "\e90f";
}
.icon-receipt:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e911";
}
.icon-worldwide:before {
  content: "\e912";
}
