
.home-page {
    position: relative;
    
    .bg-light{
        overflow: hidden;
    }
    
    .bg-dark {
        display: flex;
        align-items: center;
        overflow: visible;
        z-index: 300;
        justify-content: flex-end;
    }

    .card-home{
        color: $black;
        width: 150%;
        height: 400px;
        margin-right: -75%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .add-payment-btn-container {
        position: absolute;
        top: calc(200px - 5rem);
        right: -5rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .logout-btn-container {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
    }

    .payment-completed {
        color: $red;
        display: flex;
        font-weight: bold;
        width: 85%;
        margin-top:4rem;
        line-height: 1.6rem;
        .icon{
            margin-right:1rem;
        }     
    }
}

