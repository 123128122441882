input {
    border: none;
    border-bottom: 1px solid $color-main;
    background-color: transparent;
    padding: .5rem;
    margin: .6rem 0;
    font-family: $font-family;
    font-size: 1rem;  
 
    &::placeholder{
        color: $black;
        font-family: $font-family;
        opacity: .5;
    }

    &:focus {
        outline: none;
        border-bottom: 2px solid $color-second;

        &::placeholder{
            color: $color-main;           
        }
    }
    &.readonly:focus {
        outline: none;
        border-bottom: 1px solid $color-main;

        &::placeholder{
            color: $black;           
        }
    }

    &.input-right {
        text-align: right
    }

    &.input-light{
        border-bottom: 1px solid $white;
        color: $white;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $white;
            opacity: 1;
        }                  
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $white;
        }                  
        &::-ms-input-placeholder { /* Microsoft Edge */
        color: $white;
        }

        &:focus {            
            border-bottom: 2px solid $white;
    
            &::placeholder{
                color: $white;
                opacity: .6;           
            }
        }
    }   

    &.not-empty{
        border-bottom: 2px solid $color-second;
    }

    &.error-input{
        color: $red;
        border-bottom-color: $red;
    }
    
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}