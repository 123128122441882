

.left-half, .right-half {
    height: 100vh;   
}

.right-half {
    box-shadow: -3px 0px 5px 0px $shadow-color;
}

.payment-page {
    .bg-light{
        overflow-y: auto;   
    }
    
    .go-home-btn-container {
        position: relative;
        button {
            position: absolute;
            top: 1rem;
            left: 1rem;
        }
    }

    div.box-container:nth-of-type(3) {
        margin-top: 3rem;
    }
    
    .box-container {
        width: 80%;
        margin: 2rem auto;
    
        input {
            width: 80%;
        }
    
        .col-6:nth-child(even){
            display: flex;
            justify-content: flex-end;
        }

        .react-select{
            width: 80%;
            .react-select__value-container{
                justify-content: flex-end;
            }
        }
        
    }
   
    .payment-info-container {
        .box {
            .react-select{
                width: 80%;
                .react-select__value-container{
                    justify-content: flex-end;
                }
                .react-select__menu-list{
                    max-height: 150px;
                }
            }            
        }
    } 
    
    .input-error-msg {
        color: $red;
        position: absolute;
        bottom: -.5rem;      
        width: 80%;
        z-index: 200;
             
        &.input-right {                    
            text-align: right;  
        }
    } 
    .content {
        margin-left: 3rem;
    }   
    
    .currency-input{
       position: relative;
        .currency-span {           
            position: absolute;
            bottom: 1.2rem;
            right: 1rem;
        }  
    }
}



.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    h2 {
        color: $white;
    }

    .header-sidebar {
        height: 30vh;
        display: flex;
        justify-content: center;        
        font-weight: bold;
        .header-content {      
            width: 80%;   
            .header-items {
                display: flex;
                align-items: center;
                min-height: 4rem;           
            }    
            .amount-label, .country-label {
                justify-content: flex-end;
                text-align: right;
            }
            .amount, .country {
                justify-content: center;
            }
            .amount {
                font-size: 3rem;
            }
            .country {
                font-size: 2rem;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }

    .credit-card-info-section {    
        h2{
            width: 70%;
            text-align: right;
        }
        .credit-card-info-container {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            .input-container {
                width: 100%;
                margin: 1.5rem 0 2rem 0;
                .single-input{
                    position: relative;
                    width: inherit;
                }
                input {
                    width: 70%; 
                    margin-right: 1rem;                      
                }
            }
            .btn-container{
                width: 70%;
                text-align: right;
            }

            .input-error-msg {                
                width: 100%;   
                white-space: nowrap     
            }
            .cc-expired{
                bottom: auto;
            }
        }

        .cc-image {            
            .icon-container {
                .icon {
                    display: none;
                }
                &::before  {
                    content:'';
                    background-image: url($url-discover);
                    background-size: 33px;
                    height: 20px;
                    width: 35px;
                    margin-left: -10px;
                }
            }

            &.Mastercard{
                .icon-container {
                    &::before  {
                         background-image: url($url-mastercard);                        
                    }
                }
            }
            &.Amex{
                .icon-container {
                    &::before  {
                         background-image: url($url-amex);                        
                    }
                }
            }
            &.Discover{
                .icon-container {
                    &::before  {
                         background-image: url($url-discover);                        
                    }
                }
            }
            &.Visa{
                .icon-container {
                    &::before  {
                         background-image: url($url-visa);                        
                    }
                }
            }
        }
    }

    .footer-sidebar{
        width: 100%;
        display: flex;
    }
}



