.react-select{
    display: inline-block;

    &:focus{
        outline: none;
    }

    .react-select__control{
        border: none;
        border-bottom: 1px solid $color-main; 
        border-radius: 0;
        box-shadow: none;
        &:hover{
            border-bottom: 1px solid $color-main; 
        }   
        &.react-select__control--is-focused{
            outline: none;
        }    
        &.react-select__control--menu-is-open{
            border-bottom: 2px solid $color-second;
            .react-select__placeholder{
                color: $color-main;           
            }
        }
        .react-select__placeholder{
            color: $black;
            opacity: .5;
        }
    }

    .react-select__indicator-separator{
        display: none;
    }

    
    .react-select__option{
        text-align: center;
        &:hover{
            background-color: $color-main;            
        }   
        &.react-select__option--is-focused{   
            background-color: $color-main;       
        }
        &.react-select__option--is-selected{   
            background-color: $color-second;       
        }
    }

    .react-select__value-container{
        outline: none;
    }
}