/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/** FONT **/
@font-face {
  font-family: 'Nunito';
  src: url("../../fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../../fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: bold; }

@font-face {
  font-family: 'icomoon';
  src: url("../../fonts/icomoon.eot?9c2vzv");
  src: url("../../fonts/icomoon.eot?9c2vzv#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?9c2vzv") format("truetype"), url("../../fonts/icomoon.woff?9c2vzv") format("woff"), url("../../fonts/icomoon.svg?9c2vzv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

/** COLORS **/
html {
  font-family: "Nunito", sans-serif;
  font-size: 18px; }

/** LOGO **/
.logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: #163444;
  position: absolute;
  left: 1rem;
  top: 2rem;
  left: 2rem; }
  .logo.white {
    color: #fff; }
  .logo.bottom {
    bottom: 1rem; }
  .logo.inside-circle {
    top: 20%;
    left: 7%;
    font-size: 4rem; }

/** BACKGROUND **/
.bg-light {
  background-color: #e8e8e8;
  z-index: 100; }

.bg-dark {
  background-color: #163444;
  color: #fff;
  z-index: 100; }

/** HEADINGS **/
h2 {
  color: #163444;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 2rem;
  font-weight: bold; }

/** CIRCLE **/
.circle {
  position: absolute;
  top: -200px;
  left: -200px;
  z-index: -1; }
  .circle.bottom {
    top: auto;
    left: auto;
    bottom: -200px;
    right: -200px; }

/* BOX */
.box {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem 0;
  box-shadow: 3px 5px 6px 0px #a6a6a6; }

/** SCROLLBAR **/
::-webkit-scrollbar-track {
  -webkit-box-shadow: none; }

::-webkit-scrollbar-track {
  background-color: #e8e8e8 !important; }

::-webkit-scrollbar {
  width: 7px;
  background-color: #163444 !important; }

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #163444 !important; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-sad:before {
  content: "\e900"; }

.icon-address:before {
  content: "\e901"; }

.icon-arroba:before {
  content: "\e902"; }

.icon-calendar:before {
  content: "\e903"; }

.icon-clapping:before {
  content: "\e904"; }

.icon-coin:before {
  content: "\e905"; }

.icon-credit-card:before {
  content: "\e906"; }

.icon-credit-cards-payment:before {
  content: "\e907"; }

.icon-earth:before {
  content: "\e908"; }

.icon-euro-coin:before {
  content: "\e909"; }

.icon-euro:before {
  content: "\e90a"; }

.icon-invoice:before {
  content: "\e90b"; }

.icon-key:before {
  content: "\e90c"; }

.icon-logout:before {
  content: "\e90d"; }

.icon-phone-call:before {
  content: "\e90e"; }

.icon-placeholder-filled-point:before {
  content: "\e90f"; }

.icon-receipt:before {
  content: "\e910"; }

.icon-user:before {
  content: "\e911"; }

.icon-worldwide:before {
  content: "\e912"; }

.icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center; }

button {
  background-color: #ee4d2a;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #ee4d2a;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: .7rem 0 .7rem 3.8rem;
  min-width: 200px; }
  button:focus {
    outline: none; }
  button.disabled {
    pointer-events: none;
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    cursor: not-allowed; }
  button.naked-button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    padding: 0; }
    button.naked-button.dark-button {
      color: #000; }
    button.naked-button:hover {
      box-shadow: none;
      transform: none; }
  button.small-button {
    min-width: 100px; }
  button.big-button {
    padding: .7rem 0 .7rem 6rem;
    min-width: 13rem; }
  button img {
    width: 2rem; }

.bg-button-round, .button-round {
  height: 10rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%; }

.bg-button-round {
  animation: pulse 1.2s ease infinite;
  background: #163444;
  position: absolute; }

.button-round {
  background-color: #163444;
  color: #fff;
  box-shadow: 3px 5px 6px 0px #a6a6a6;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  line-height: 2.5rem;
  text-transform: uppercase; }

input {
  border: none;
  border-bottom: 1px solid #e8e8e8;
  background-color: transparent;
  padding: .5rem;
  margin: .6rem 0;
  font-family: "Nunito", sans-serif;
  font-size: 1rem; }
  input::placeholder {
    color: #000;
    font-family: "Nunito", sans-serif;
    opacity: .5; }
  input:focus {
    outline: none;
    border-bottom: 2px solid #163444; }
    input:focus::placeholder {
      color: #e8e8e8; }
  input.readonly:focus {
    outline: none;
    border-bottom: 1px solid #e8e8e8; }
    input.readonly:focus::placeholder {
      color: #000; }
  input.input-right {
    text-align: right; }
  input.input-light {
    border-bottom: 1px solid #fff;
    color: #fff; }
    input.input-light::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 1; }
    input.input-light:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff; }
    input.input-light::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #fff; }
    input.input-light:focus {
      border-bottom: 2px solid #fff; }
      input.input-light:focus::placeholder {
        color: #fff;
        opacity: .6; }
  input.not-empty {
    border-bottom: 2px solid #163444; }
  input.error-input {
    color: #ff1a1a;
    border-bottom-color: #ff1a1a; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.react-select {
  display: inline-block; }
  .react-select:focus {
    outline: none; }
  .react-select .react-select__control {
    border: none;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0;
    box-shadow: none; }
    .react-select .react-select__control:hover {
      border-bottom: 1px solid #e8e8e8; }
    .react-select .react-select__control.react-select__control--is-focused {
      outline: none; }
    .react-select .react-select__control.react-select__control--menu-is-open {
      border-bottom: 2px solid #163444; }
      .react-select .react-select__control.react-select__control--menu-is-open .react-select__placeholder {
        color: #e8e8e8; }
    .react-select .react-select__control .react-select__placeholder {
      color: #000;
      opacity: .5; }
  .react-select .react-select__indicator-separator {
    display: none; }
  .react-select .react-select__option {
    text-align: center; }
    .react-select .react-select__option:hover {
      background-color: #e8e8e8; }
    .react-select .react-select__option.react-select__option--is-focused {
      background-color: #e8e8e8; }
    .react-select .react-select__option.react-select__option--is-selected {
      background-color: #163444; }
  .react-select .react-select__value-container {
    outline: none; }

.lds-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: -10px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader-container {
  position: relative;
  margin-left: 1rem; }

.fade-right-enter {
  opacity: 0;
  transform: translateX(-100%); }

.fade-right-enter.fade-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in; }

.fade-right-exit {
  opacity: 1;
  transform: 0; }

.fade-right-exit.fade-right-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-in; }

.fade-left-enter {
  opacity: 0;
  transform: translateX(100%); }

.fade-left-enter.fade-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in; }

.fade-left-exit {
  opacity: 1;
  transform: 0; }

.fade-left-exit.fade-left-exit-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition: all 300ms ease-in; }

div.transition-group {
  position: relative; }

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

@keyframes pulse {
  0% {
    transform: scale(1, 1); }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1.5);
    opacity: 0; } }

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 701;
  box-sizing: border-box;
  color: #163444;
  background-color: #fff !important;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 1.5rem;
  min-height: 30%;
  height: 50%;
  overflow: hidden;
  min-width: 40%; }
  .popup-container.popup-small {
    height: 35%; }
    .popup-container.popup-small .popup-content {
      width: 85%;
      margin: 0 auto;
      line-height: 1.5rem; }
  .popup-container.popup-medium {
    height: 60%; }
    .popup-container.popup-medium .popup-content {
      height: 75%; }
  .popup-container.popup-large {
    height: 70%;
    min-width: 50%; }
    .popup-container.popup-large .popup-content {
      height: 75%;
      width: 85%;
      margin: 0 auto; }
  .popup-container.overflow-visible {
    overflow: visible; }
    .popup-container.overflow-visible .popup-content {
      overflow: visible;
      overflow-y: visible; }
  .popup-container .popup-header {
    text-transform: uppercase; }
  .popup-container .popup-content {
    min-height: 40%;
    overflow: hidden;
    overflow-y: auto; }
    .popup-container .popup-content p {
      margin: 0.5rem 0; }
    .popup-container .popup-content.flex {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .popup-container .popup-content .react-select__menu-list {
      max-height: 160px; }
    .popup-container .popup-content.no-content {
      display: none; }
  .popup-container h3 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    font-weight: 500; }
  .popup-container li {
    margin: 0.5rem 0; }
  .popup-container .error-message {
    font-family: "Nunito", sans-serif;
    font-size: 1.5rem; }

.popup-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #163444;
  opacity: .4;
  z-index: 700;
  top: 0;
  left: 0; }

.footer-popup {
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px; }

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 800;
  cursor: pointer;
  transform: rotate(45deg); }

.left-half, .right-half {
  height: 100vh; }

.right-half {
  box-shadow: -3px 0px 5px 0px #a6a6a6; }

.payment-page .bg-light {
  overflow-y: auto; }

.payment-page .go-home-btn-container {
  position: relative; }
  .payment-page .go-home-btn-container button {
    position: absolute;
    top: 1rem;
    left: 1rem; }

.payment-page div.box-container:nth-of-type(3) {
  margin-top: 3rem; }

.payment-page .box-container {
  width: 80%;
  margin: 2rem auto; }
  .payment-page .box-container input {
    width: 80%; }
  .payment-page .box-container .col-6:nth-child(even) {
    display: flex;
    justify-content: flex-end; }
  .payment-page .box-container .react-select {
    width: 80%; }
    .payment-page .box-container .react-select .react-select__value-container {
      justify-content: flex-end; }

.payment-page .payment-info-container .box .react-select {
  width: 80%; }
  .payment-page .payment-info-container .box .react-select .react-select__value-container {
    justify-content: flex-end; }
  .payment-page .payment-info-container .box .react-select .react-select__menu-list {
    max-height: 150px; }

.payment-page .input-error-msg {
  color: #ff1a1a;
  position: absolute;
  bottom: -.5rem;
  width: 80%;
  z-index: 200; }
  .payment-page .input-error-msg.input-right {
    text-align: right; }

.payment-page .content {
  margin-left: 3rem; }

.payment-page .currency-input {
  position: relative; }
  .payment-page .currency-input .currency-span {
    position: absolute;
    bottom: 1.2rem;
    right: 1rem; }

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }
  .sidebar h2 {
    color: #fff; }
  .sidebar .header-sidebar {
    height: 30vh;
    display: flex;
    justify-content: center;
    font-weight: bold; }
    .sidebar .header-sidebar .header-content {
      width: 80%; }
      .sidebar .header-sidebar .header-content .header-items {
        display: flex;
        align-items: center;
        min-height: 4rem; }
      .sidebar .header-sidebar .header-content .amount-label, .sidebar .header-sidebar .header-content .country-label {
        justify-content: flex-end;
        text-align: right; }
      .sidebar .header-sidebar .header-content .amount, .sidebar .header-sidebar .header-content .country {
        justify-content: center; }
      .sidebar .header-sidebar .header-content .amount {
        font-size: 3rem; }
      .sidebar .header-sidebar .header-content .country {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center; }
  .sidebar .credit-card-info-section h2 {
    width: 70%;
    text-align: right; }
  .sidebar .credit-card-info-section .credit-card-info-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start; }
    .sidebar .credit-card-info-section .credit-card-info-container .input-container {
      width: 100%;
      margin: 1.5rem 0 2rem 0; }
      .sidebar .credit-card-info-section .credit-card-info-container .input-container .single-input {
        position: relative;
        width: inherit; }
      .sidebar .credit-card-info-section .credit-card-info-container .input-container input {
        width: 70%;
        margin-right: 1rem; }
    .sidebar .credit-card-info-section .credit-card-info-container .btn-container {
      width: 70%;
      text-align: right; }
    .sidebar .credit-card-info-section .credit-card-info-container .input-error-msg {
      width: 100%;
      white-space: nowrap; }
    .sidebar .credit-card-info-section .credit-card-info-container .cc-expired {
      bottom: auto; }
  .sidebar .credit-card-info-section .cc-image .icon-container .icon {
    display: none; }
  .sidebar .credit-card-info-section .cc-image .icon-container::before {
    content: '';
    background-image: url("../../img/CreditCard_Icons_Discover.png");
    background-size: 33px;
    height: 20px;
    width: 35px;
    margin-left: -10px; }
  .sidebar .credit-card-info-section .cc-image.Mastercard .icon-container::before {
    background-image: url("../../img/CreditCard_Icons_Mastercard.jpg"); }
  .sidebar .credit-card-info-section .cc-image.Amex .icon-container::before {
    background-image: url("../../img/CreditCard_Icons_Amex.png"); }
  .sidebar .credit-card-info-section .cc-image.Discover .icon-container::before {
    background-image: url("../../img/CreditCard_Icons_Discover.png"); }
  .sidebar .credit-card-info-section .cc-image.Visa .icon-container::before {
    background-image: url("../../img/CreditCard_Icons_Visa.jpg"); }
  .sidebar .footer-sidebar {
    width: 100%;
    display: flex; }

.fullscreen {
  height: 100vh; }
  .fullscreen .bg-dark, .fullscreen .bg-light {
    height: inherit; }

.login-page .bg-dark {
  display: flex;
  align-items: center; }

.login-page .login-container {
  margin-left: -275px;
  height: 400px;
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }
  .login-page .login-container .input-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    height: 60%;
    justify-content: center; }
    .login-page .login-container .input-container .icon {
      color: #000; }
    .login-page .login-container .input-container input {
      width: 80%; }
  .login-page .login-container .btn-container {
    width: 80%;
    display: flex;
    justify-content: flex-end; }

.home-page {
  position: relative; }
  .home-page .bg-light {
    overflow: hidden; }
  .home-page .bg-dark {
    display: flex;
    align-items: center;
    overflow: visible;
    z-index: 300;
    justify-content: flex-end; }
  .home-page .card-home {
    color: #000;
    width: 150%;
    height: 400px;
    margin-right: -75%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    font-size: 2rem;
    line-height: 2.5rem; }
  .home-page .add-payment-btn-container {
    position: absolute;
    top: calc(200px - 5rem);
    right: -5rem;
    font-size: 1rem;
    line-height: 1.5rem; }
  .home-page .logout-btn-container {
    position: absolute;
    bottom: 1rem;
    left: 1rem; }
  .home-page .payment-completed {
    color: #ff1a1a;
    display: flex;
    font-weight: bold;
    width: 85%;
    margin-top: 4rem;
    line-height: 1.6rem; }
    .home-page .payment-completed .icon {
      margin-right: 1rem; }

@media screen and (max-width: 1369px) {
  html {
    font-size: 14px; }
  h2 {
    text-transform: none; }
  .sidebar .credit-card-info-section h2 {
    text-align: left;
    margin-left: 1rem; }
  .circle {
    top: -300px;
    left: -300px; }
    .circle svg {
      width: 90%; }
    .circle.bottom {
      bottom: -300px;
      right: -300px; }
  .payment-page div.box-container:nth-of-type(3) {
    margin-top: 2rem; } }
